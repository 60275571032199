import { useState, useEffect, useMemo } from "react";
import moment from "moment";

const DEFAULT_FILTERS = {
  categoryIds: [],
  depotIds: [],
  statuses: ["ISSUE_RAISED", "IN_PROGRESS"],
  locationOrRefLike: "",
  fromDate: moment().subtract(6, "day").startOf("day").format(),
  toDate: moment().endOf("day").format(),
  asAt: moment().format(),
};

export default function useSessionStorageFilter(tenantId) {
  const sessionStorageKey = useMemo(
    () => `near_miss.filters_${tenantId}`,
    [tenantId]
  );

  const [filters, setFilters] = useState(() => {
    const storedFilters = sessionStorage.getItem(sessionStorageKey);
    if (!storedFilters) return DEFAULT_FILTERS;

    const storedData = JSON.parse(storedFilters);
    return moment().diff(moment(storedData.asAt), "days") > 0
      ? DEFAULT_FILTERS
      : storedData;
  });

  useEffect(() => {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(filters));
  }, [filters]);

  return [filters, setFilters];
}
