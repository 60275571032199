import React from "react";
import DefaultLayout from "../../components/DefaultLayout";
import { Box, Container, Typography } from "@mui/material";
import Filters from "./Filters";
import NearMissList from "./NearMissList";
import NearMissListSkeleton from "./NearMissListSkeleton";
import StatsSkeleton from "./StatsSkeleton";
import Stats from "./Stats";
import { NearMissesQuery, CategoriesQuery, DepotsQuery } from "./Query";
import { useQuery } from "@apollo/client";
import NextPreviousPagination from "../../components/NextPreviousPagination";
import useSessionStorageFilter from "../near-miss/SessionStorageFilter";

const NearMissIndexPage = ({ tenantId }) => {
  const [filters, setFilters] = useSessionStorageFilter(tenantId);

  const handleFilterChange = (newValues) => {
    setFilters(newValues);
  };

  const hasFilters = Object.values(filters).some(
    (filterValue) => filterValue !== null
  );

  const { asAt, ...nearMissFilters } = filters;

  const { loading, error, data, fetchMore } = useQuery(NearMissesQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      filter: nearMissFilters,
      filterStats: nearMissFilters,
    },
  });

  const { data: categoriesData } = useQuery(CategoriesQuery, {
    fetchPolicy: "cache-and-network",
  });
  const { data: DepotsData } = useQuery(DepotsQuery, {
    fetchPolicy: "cache-and-network",
  });

  const nearMissConnection = data?.nearMisses.all;
  const categoryConnection = categoriesData?.nearMissCategories?.all;

  const depotConnection = DepotsData?.depots?.all;
  const hasNearMisses = nearMissConnection?.edges.length > 0;

  return (
    <DefaultLayout>
      <Container maxWidth={"lg"} disableGutters sx={{ margin: "0" }}>
        <Typography variant="h5" gutterBottom sx={{ marginBottom: 4 }}>
          Near miss
        </Typography>

        {!hasFilters && !hasNearMisses && (
          <div>
            <Typography variant="subtitle1">No near misses</Typography>
          </div>
        )}

        <>
          {(hasFilters || hasNearMisses) && (
            <Filters
              initialValues={filters}
              onChange={handleFilterChange}
              depotConnection={depotConnection}
              categoryConnection={categoryConnection}
            />
          )}
          {loading && (
            <>
              <StatsSkeleton />
              <NearMissListSkeleton />
            </>
          )}
          {hasNearMisses && !loading && (
            <>
              <Box my={4}>
                {categoryConnection && (
                  <Stats
                    timeOfDayData={data?.nearMisses.timeOfDayStats}
                    categoryConnection={categoryConnection}
                    categoriesData={data?.nearMisses.categoriesStats}
                  />
                )}
              </Box>
              <NearMissList nearMissConnection={nearMissConnection} />
              <NextPreviousPagination
                {...{
                  fetchMore,
                  loading,
                  pageInfo: nearMissConnection.pageInfo,
                }}
              />
            </>
          )}
          {hasFilters && !hasNearMisses && !loading && (
            <Typography variant="subtitle1">
              No records match your search criteria.
            </Typography>
          )}
        </>
      </Container>
    </DefaultLayout>
  );
};

export default NearMissIndexPage;
