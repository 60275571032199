import React, { useRef } from "react";
import {
  Typography,
  Stack,
  Box,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  List,
  ListItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import {
  NearMissesQuery,
  CategoriesQuery,
  DepotsQuery,
} from "../near-miss.index/Query";
import { useQuery } from "@apollo/client";
import moment from "moment";
import StatusChip from "../near-miss/StatusChip";
import NearMissListSkeleton from "./NearMissListSkeleton";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink, useParams } from "react-router-dom";
import NextPreviousPagination from "../../components/NextPreviousPagination";
import DateRangeFilter from "../near-miss/DateRangeFilter";
import useSessionStorageFilter from "../near-miss/SessionStorageFilter";

import {
  ISSUE_RAISED,
  IN_PROGRESS,
  COMPLETE,
  toDisplayName,
} from "./../near-miss/Statuses";

function SidebarPanel({ tenantId }) {
  const theme = useTheme();
  let { id } = useParams();
  const [filters, setFilters] = useSessionStorageFilter(tenantId);

  const handleChangeFilter = (filterName, event) => {
    const {
      target: { value },
    } = event;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const { asAt, ...nearMissFilters } = filters;
  const { loading, error, data, fetchMore } = useQuery(NearMissesQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      filter: nearMissFilters,
      filterStats: nearMissFilters,
    },
  });

  const { data: categoriesData } = useQuery(CategoriesQuery, {
    fetchPolicy: "cache-and-network",
  });

  const { data: DepotsData } = useQuery(DepotsQuery, {
    fetchPolicy: "cache-and-network",
  });

  const nearMissConnection = data?.nearMisses.all;
  const categories = categoriesData?.nearMissCategories?.all.edges;
  const depots = DepotsData?.depots?.all.edges;

  const getStyles = (name, values, theme) => ({
    fontWeight:
      values.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  });

  const getCategoryName = (id) => {
    const category = categories?.find(({ node }) => node.id === id);
    return category?.node?.name;
  };

  const getDepotName = (id) => {
    const depot = depots?.find(({ node }) => node.id === id);
    return depot?.node?.name;
  };

  const typingTimeoutRef = useRef(null);
  const handleChangeTextBox = (filterName, e) => {
    const value = e.target.value;
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: value,
      }));
    }, 500);
  };

  const dateRangeValue = [
    moment(filters.fromDate ?? moment().subtract(6, "day")),
    moment(filters.toDate ?? moment()),
  ];

  const handleChangeDateRange = (dateRange) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ["fromDate"]: dateRange[0]?.startOf("day").format(),
      ["toDate"]: dateRange[1]?.endOf("day").format(),
    }));
  };

  return (
    <Stack
      component={"ul"}
      role="list"
      style={{ listStyleType: "none", padding: 0, margin: 0 }}
      spacing={2}
    >
      <FormControl variant="outlined" spacing={1}>
        <DateRangeFilter
          dateRangeValue={dateRangeValue}
          onChangeDateRange={handleChangeDateRange}
          size={"medium"}
        />
      </FormControl>

      <FormControl variant="outlined">
        <InputLabel>Status</InputLabel>
        <Select
          labelId="status-select-outlined-label"
          id="status-select-outlined"
          label="Status"
          multiple
          value={filters.statuses}
          onChange={(event) => handleChangeFilter("statuses", event)}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={toDisplayName(value)} size="small" />
              ))}
            </Box>
          )}
        >
          <MenuItem
            style={getStyles(ISSUE_RAISED, filters.statuses, theme)}
            value={ISSUE_RAISED}
          >
            <Checkbox checked={filters.statuses.indexOf(ISSUE_RAISED) > -1} />
            <ListItemText primary={toDisplayName(ISSUE_RAISED)} />
          </MenuItem>
          <MenuItem
            style={getStyles(IN_PROGRESS, filters.statuses, theme)}
            value={IN_PROGRESS}
          >
            <Checkbox checked={filters.statuses.indexOf(IN_PROGRESS) > -1} />
            <ListItemText primary={toDisplayName(IN_PROGRESS)} />
          </MenuItem>
          <MenuItem
            style={getStyles(COMPLETE, filters.statuses, theme)}
            value={COMPLETE}
          >
            <Checkbox checked={filters.statuses.indexOf(COMPLETE) > -1} />
            <ListItemText primary={toDisplayName(COMPLETE)} />
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel id="category-select-outlined-label">Category</InputLabel>
        <Select
          labelId="category-select-outlined-label"
          id="category-select-outlined"
          label="Category"
          multiple
          value={filters.categoryIds}
          onChange={(event) => handleChangeFilter("categoryIds", event)}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {categories &&
                selected.map((value) => (
                  <Chip
                    key={value}
                    label={getCategoryName(value)}
                    size="small"
                  />
                ))}
            </Box>
          )}
        >
          {categories &&
            categories.map(({ node }) => (
              <MenuItem
                key={node.id}
                value={node.id}
                style={getStyles(node.id, filters.categoryIds, theme)}
              >
                <Checkbox checked={filters.categoryIds.indexOf(node.id) > -1} />
                <ListItemText primary={node.name} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel id="depot-select-outlined-label">Depot</InputLabel>
        <Select
          labelId="depot-select-outlined-label"
          id="depot-select-outlined"
          label="Depot"
          multiple
          value={filters.depotIds}
          onChange={(event) => handleChangeFilter("depotIds", event)}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {depots &&
                selected.map((value) => (
                  <Chip key={value} label={getDepotName(value)} size="small" />
                ))}
            </Box>
          )}
        >
          {depots &&
            depots.map(({ node }) => (
              <MenuItem
                key={node.id}
                value={node.id}
                style={getStyles(node.id, filters.depotIds, theme)}
              >
                <Checkbox checked={filters.depotIds.indexOf(node.id) > -1} />
                <ListItemText primary={node.name} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" fullWidth>
        <TextField
          name="locationOrRefLike"
          autoComplete="off"
          label="Search"
          type="search"
          defaultValue={filters.locationOrRefLike}
          onChange={(event) => handleChangeTextBox("locationOrRefLike", event)}
        />
      </FormControl>
      {loading && <NearMissListSkeleton />}

      <div
        style={{
          height: "calc(100vh - 369px)",
          overflowY: "auto",
        }}
      >
        <List sx={{ mb: 2 }}>
          {nearMissConnection?.edges &&
            nearMissConnection.edges.map(({ node: nearMiss }) => (
              <React.Fragment key={nearMiss.id}>
                <Divider />
                <RouterLink
                  to={`/near-miss/${nearMiss.id}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <ListItem
                    button
                    divider
                    sx={{
                      bgcolor: nearMiss.id == id ? "rgba(0, 0, 0, 0.08)" : "",
                    }}
                  >
                    <Stack direction="column" spacing={2}>
                      <Box flexGrow={1}>
                        <Stack spacing={2} direction={"row"}>
                          <Box color={"text.secondary"}>
                            # {nearMiss.reference}
                          </Box>
                          <StatusChip status={nearMiss.status} size="small" />
                        </Stack>
                        <Stack
                          spacing={2}
                          direction={"row"}
                          alignItems="center"
                          justifyContent="flex-start"
                        >
                          <Box>{nearMiss.category.name} </Box>
                        </Stack>
                        <Stack
                          color={"text.secondary"}
                          direction="row"
                          alignItems="center"
                          gap={2}
                        >
                          <div>
                            <small>{nearMiss.depot.name}</small>
                          </div>{" "}
                          <div>
                            <small>
                              Raised on{" "}
                              {moment(nearMiss.eventDate).format("lll")}
                            </small>
                          </div>
                        </Stack>
                      </Box>
                    </Stack>
                  </ListItem>
                </RouterLink>
              </React.Fragment>
            ))}
          {nearMissConnection?.edges.length == 0 && (
            <Typography variant="subtitle1">
              No records match your search criteria.
            </Typography>
          )}
        </List>
        {nearMissConnection && (
          <NextPreviousPagination
            {...{
              fetchMore,
              loading,
              pageInfo: nearMissConnection.pageInfo,
            }}
          />
        )}
      </div>
    </Stack>
  );
}

export default SidebarPanel;
